import React from "react";
import { graphql } from "gatsby";
import Layout from "../../components/Layout";
import SEO from "../../components/seo";
import BigTagList from "../../components/PageCode/BigTagList";
import BigTitleButtonArea from "../../components/PageCode/BigTitleButtonArea";
import TutorialsArea from "../../components/PageCode/TutorialsArea";
import PostsArea from "../../components/PageCode/PostsArea";
import bigTags from "../../../content/bigTagList.json";

export const pageQuery = graphql`
  query DesignQuery {
    allTutorials: allMarkdownRemark(
      filter: {
        fileAbsolutePath: { regex: "//content/data/design/tutorials//" }
      }
      sort: { fields: [frontmatter___date], order: DESC }
      limit: 16
    ) {
      edges {
        node {
          excerpt(truncate: true, pruneLength: 100)
          fields {
            slug
          }
          frontmatter {
            title
            tutorial
            image {
              childImageSharp {
                fluid(maxWidth: 800) {
                  src
                }
              }
            }
            date(locale: "ko", formatString: "YYYY년 MM월 DD일")
            tags
            author
          }
        }
      }
    }

    allPosts: allMarkdownRemark(
      filter: { fileAbsolutePath: { regex: "//content/data/design/posts//" } }
      sort: { fields: [frontmatter___date], order: DESC }
      limit: 16
    ) {
      edges {
        node {
          excerpt(truncate: true, pruneLength: 80)
          fields {
            slug
          }
          frontmatter {
            title
            tutorial
            image {
              childImageSharp {
                fluid(maxWidth: 800) {
                  src
                }
              }
            }
            date(locale: "ko", formatString: "YYYY년 MM월 DD일")
            tags
            author
          }
        }
      }
    }

    allTags: allMarkdownRemark(
      filter: { fileAbsolutePath: { regex: "//content/data/design//" } }
    ) {
      group(field: frontmatter___tags) {
        fieldValue
        totalCount
      }
    }
  }
`;

const pageName = "design";

const DesignPage = ({ location, data }) => {
  // console.log(data.allTutorials.edges);
  return (
    <Layout location={location} bgColor="#F3F2F2">
      <SEO title="Design" />

      <main role="main" style={{ marginTop: "56px" }}>
        <BigTagList list={bigTags[pageName]} menu={pageName} />
        {data.allTutorials.edges.length > 0 && <BigTitleButtonArea />}
        {data.allTutorials.edges.length > 0 && (
          <TutorialsArea
            linkTo={`/${pageName}/tutorials`}
            tutorials={data.allTutorials.edges}
          />
        )}
        <PostsArea
          title={"Design Posts"}
          linkTo={`/${pageName}/posts`}
          posts={data.allPosts.edges}
        />
      </main>
    </Layout>
  );
};

export default DesignPage;
